/*
 * This is all cribbed _heavily_ from the W3C examples.
 *	 https://w3c.github.io/aria-practices/examples/dialog-modal/dialog.html
 */
import { domReady, qs, qsa } from 'lib/dom.js'
import { dispatch, off, on, stop } from 'lib/events.js'
import { fadeIn, fadeOut } from 'lib/transition.js'

let ignoreFocusChanges = false

/*
 * Exported so scripts using dialogs can check if one of their dialogs is
 * active.
 */
export let activeDialog = null

const isBodyOverflowing = (rect => {
	return Math.round(rect.left + rect.right) < window.innerWidth
})(document.body.getBoundingClientRect())

const scrollbarWidth = (scrollDiv => {
	scrollDiv.className = 'dialog-component-scrollbar-measure'
	document.body.appendChild(scrollDiv)
	const width = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
	document.body.removeChild(scrollDiv)

	return width
})(document.createElement('div'))

function focusAbleDescendants(element) {
	return qsa('a, button, input, select, textarea', element).filter(elm => {
		return isFocusable(elm)
	})
}

function focusFirstDescendant(element) {
	const candidates = focusAbleDescendants(element)

	for (var i = 0; i < candidates.length; i++) {
		var child = candidates[i]

		if (attemptFocus(child)) {
			return true
		}
	}

	return false
}

function attemptFocus(element) {
	if (!isFocusable(element)) {
		return false
	}

	ignoreFocusChanges = true

	try {
		element.focus()
	} catch (e) { /* who cares */ }

	ignoreFocusChanges = false

	return document.activeElement === element
}

function isFocusable(element) {
	if (element.tabIndex < 0) {
		return false
	}

	if (element.disabled) {
		return false
	}

	switch (element.nodeName) {
		case 'A':
			return !!element.href && element.rel != 'ignore'
		case 'INPUT':
			return element.type != 'hidden'
		case 'BUTTON':
		case 'SELECT':
		case 'TEXTAREA':
			return true
		default:
			return false
	}
}

/*
 * Close button
 */
function dismissDialogViaCloseButton({ target }) {
	if (target.nodeName === 'BUTTON' && target.dataset.dismiss === 'dialog') {
		detachDialog(target.closest('.dialog-component'))
	}
}

/*
 * Trap focus
 */
function trapFocusInDialog({ target }) {
	if (!activeDialog || ignoreFocusChanges) {
		return
	}

	if (!activeDialog.contains(target)) {
		focusFirstDescendant(activeDialog)
	}
}

/*
 * Handle hitting escape with a dialog open.
 */
const NON_DISMISSABLE_NODE_NAMES = ['INPUT', 'SELECT', 'TEXTAREA']

function dismissDialogViaEscape(keyupEvent) {
	if (keyupEvent.key === 'Escape' && !NON_DISMISSABLE_NODE_NAMES.includes(document.activeElement.nodeName)) {
		detachDialog(activeDialog)
	}
}

/*
 * Attach all listeners for an open dialog.
 */
function attachListeners(dialog) {
	if (dialog.dataset.dismissable !== undefined) {
		on(document, 'keyup', dismissDialogViaEscape, { passive: true })

		const backdrop = dialog.closest('.dialog-component-backdrop')

		if (backdrop.dataset.dismissEventAttached === undefined) {
			backdrop.dataset.dismissEventAttached = true

			/*
			 * The target has to be checked here because the dialog's contents are
			 * children of the backdrop, so clicking within the dialog contents is
			 * technically clicking the backdrop.
			 */
			on(backdrop, 'click', ({ target }) => {
				if (target === backdrop) {
					detachDialog(dialog)
				}
			}, { passive: true })
		}
	}

	on(document, 'click', dismissDialogViaCloseButton, { passive: true })
	on(document, 'focus', trapFocusInDialog, { capture: true })
}

/*
 * Detach all listeners for a closed dialog.
 */
function detachListeners(dialog) {
	if (dialog.dataset.dismissable !== undefined) {
		off(document, 'keyup', dismissDialogViaEscape)
	}

	off(document, 'click', dismissDialogViaCloseButton)
	off(document, 'focus', trapFocusInDialog, { capture: true })
}

function abandonComponentHandler(target) {
	if (target === document) {
		return true
	}

	return (target.nodeName !== 'BUTTON' && target.nodeName !== 'A') || target.dataset.target === undefined || target.dataset.toggle !== 'dialog'
}

export function attachDialog(dialog, toggle = null, pushState = true, callback = null) {
	if (process.env.NODE_ENV === 'development') {
		console.log('#attachDialog', dialog)
	}

	dispatch(dialog, 'dialogComponentAttaching', { detail: { dialogToggle: toggle } })

	activeDialog = dialog

	if (pushState) {
		const url = new URL(window.location)
		url.hash = `#${ dialog.id }`
		window.history.pushState({}, '', url)
	}

	document.body.classList.add('dialog-component-active')

	if (isBodyOverflowing) {
		document.body.style.setProperty('padding-right', `${ scrollbarWidth }px`)
	}

	fadeIn(dialog.closest('.dialog-component-backdrop'), _ => {
		attachListeners(dialog)
		focusFirstDescendant(dialog)

		if (callback) {
			callback.call(null)
		}

		dispatch(dialog, 'dialogComponentAttached', { detail: { dialogToggle: toggle } })
	})
}

export function detachDialog(dialog, callback = null) {
	if (process.env.NODE_ENV === 'development') {
		console.log('#detachDialog', dialog)
	}

	dispatch(dialog, 'dialogComponentDetaching')

	detachListeners(dialog)

	let previouslyFocusedElement

	if (activeDialog === dialog) {
		previouslyFocusedElement = dialog.previouslyFocusedElement
		activeDialog = null
	}

	if (window.location.hash && window.location.hash === `#${ dialog.id }`) {
		const url = new URL(window.location)
		url.hash = ''
		window.history.pushState({}, '', url)
	}

	fadeOut(dialog.closest('.dialog-component-backdrop'), _ => {
		if (activeDialog === null) {
			document.body.style.removeProperty('padding-right')
			document.body.classList.remove('dialog-component-active')
		}

		if (previouslyFocusedElement) {
			previouslyFocusedElement.focus()
		}

		const forms = qsa('form.form-component', dialog)

		if (forms.length > 0) {
			forms.forEach(form => {
				form.reset()
				dispatch(form, 'revert')
			})
		}

		if (callback) {
			callback.call(null)
		}

		dispatch(dialog, 'dialogComponentDetached')
	})
}

/*
 * Dialog toggle
 */
on(document, 'click', clickEvent => {
	if (abandonComponentHandler(clickEvent.target)) {
		return
	}

	const target = clickEvent.target
	const dialog = qs(target.dataset.target)
	dialog.previouslyFocusedElement = document.activeElement

	attachDialog(dialog, target)

	if (target.nodeName === 'A') {
		stop(clickEvent)
	}
}, { capture: true })

on(window, 'popstate', _ => {
	if (activeDialog && !window.location.hash) {
		detachDialog(activeDialog)
	} else if (window.location.hash) {
		const hashDialog = qs(window.location.hash)

		/*
		 * The timeout here is to give everything else a chance to get in place,
		 * especially form component steps. Without this, there is a bit of jank as
		 * the form within the dialog boots up.
		 */
		if (hashDialog && hashDialog.matches('.dialog-component')) {
			setTimeout(_ => attachDialog(hashDialog), 100)
		}
	}
}, { passive: true })

domReady(_ => {
	const autoOpenDialog = qs('.dialog-component[data-open]')

	let hashDialog

	if (window.location.hash) {
		hashDialog = qs(window.location.hash)
	}

	/*
	 * The timeout here is to give everything else a chance to get in place,
	 * especially form component steps. Without this, there is a bit of jank as
	 * the form within the dialog boots up.
	 */
	if (hashDialog && hashDialog.matches('.dialog-component')) {
		setTimeout(_ => attachDialog(hashDialog), 100)
	} else if (autoOpenDialog) {
		setTimeout(_ => attachDialog(autoOpenDialog), 100)
	}
})
